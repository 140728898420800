import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2febfc6c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _33c1ba00 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _5081aef3 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _7f458548 = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _1df8cbff = () => interopDefault(import('../pages/introduction.vue' /* webpackChunkName: "pages/introduction" */))
const _982def84 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _13054ad8 = () => interopDefault(import('../pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _4a41a380 = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _3e109f9e = () => interopDefault(import('../pages/private/index.vue' /* webpackChunkName: "pages/private/index" */))
const _1949fec6 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _08fbbf29 = () => interopDefault(import('../pages/psych/index.vue' /* webpackChunkName: "pages/psych/index" */))
const _03a8d21c = () => interopDefault(import('../pages/psyche/index.vue' /* webpackChunkName: "pages/psyche/index" */))
const _00f0298c = () => interopDefault(import('../pages/request.vue' /* webpackChunkName: "pages/request" */))
const _2eeb09aa = () => interopDefault(import('../pages/roadmap/index.vue' /* webpackChunkName: "pages/roadmap/index" */))
const _ab793002 = () => interopDefault(import('../pages/schedule/index.vue' /* webpackChunkName: "pages/schedule/index" */))
const _1df9b910 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _82a1a860 = () => interopDefault(import('../pages/teacher/index.vue' /* webpackChunkName: "pages/teacher/index" */))
const _9169e896 = () => interopDefault(import('../pages/auth/leave.vue' /* webpackChunkName: "pages/auth/leave" */))
const _2fc7922a = () => interopDefault(import('../pages/auth/leave-success.vue' /* webpackChunkName: "pages/auth/leave-success" */))
const _180e568a = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _4fd59581 = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _6ad62fa8 = () => interopDefault(import('../pages/card/fail.vue' /* webpackChunkName: "pages/card/fail" */))
const _270014a7 = () => interopDefault(import('../pages/card/result.vue' /* webpackChunkName: "pages/card/result" */))
const _c2eb3e54 = () => interopDefault(import('../pages/my/alarm/index.vue' /* webpackChunkName: "pages/my/alarm/index" */))
const _f5f868b4 = () => interopDefault(import('../pages/my/authCode/index.vue' /* webpackChunkName: "pages/my/authCode/index" */))
const _58c85658 = () => interopDefault(import('../pages/my/coupon.vue' /* webpackChunkName: "pages/my/coupon" */))
const _8da7c748 = () => interopDefault(import('../pages/my/favorites/index.vue' /* webpackChunkName: "pages/my/favorites/index" */))
const _e1880152 = () => interopDefault(import('../pages/my/friends.vue' /* webpackChunkName: "pages/my/friends" */))
const _2dc70885 = () => interopDefault(import('../pages/my/money/index.vue' /* webpackChunkName: "pages/my/money/index" */))
const _23b81593 = () => interopDefault(import('../pages/my/order/index.vue' /* webpackChunkName: "pages/my/order/index" */))
const _23a6c0a4 = () => interopDefault(import('../pages/my/profile/index.vue' /* webpackChunkName: "pages/my/profile/index" */))
const _166db8c0 = () => interopDefault(import('../pages/my/reviews/index.vue' /* webpackChunkName: "pages/my/reviews/index" */))
const _180bc7e4 = () => interopDefault(import('../pages/my/subscription/index.vue' /* webpackChunkName: "pages/my/subscription/index" */))
const _2f41e020 = () => interopDefault(import('../pages/my/voucher.vue' /* webpackChunkName: "pages/my/voucher" */))
const _e8eb8228 = () => interopDefault(import('../pages/payment/fail.vue' /* webpackChunkName: "pages/payment/fail" */))
const _2a253a2a = () => interopDefault(import('../pages/payment/result.vue' /* webpackChunkName: "pages/payment/result" */))
const _52d03246 = () => interopDefault(import('../pages/private/banner.vue' /* webpackChunkName: "pages/private/banner" */))
const _135d8f34 = () => interopDefault(import('../pages/private/commonTextAreaField.vue' /* webpackChunkName: "pages/private/commonTextAreaField" */))
const _e2f12fac = () => interopDefault(import('../pages/private/complete.vue' /* webpackChunkName: "pages/private/complete" */))
const _a1a0e5ec = () => interopDefault(import('../pages/private/preGuide.vue' /* webpackChunkName: "pages/private/preGuide" */))
const _24eddea1 = () => interopDefault(import('../pages/private/privateRequestForm.vue' /* webpackChunkName: "pages/private/privateRequestForm" */))
const _3578b282 = () => interopDefault(import('../pages/private/processGuideModal.vue' /* webpackChunkName: "pages/private/processGuideModal" */))
const _1c89dd8f = () => interopDefault(import('../pages/product/suggest.vue' /* webpackChunkName: "pages/product/suggest" */))
const _2f12a8eb = () => interopDefault(import('../pages/psyche/guidance/index.vue' /* webpackChunkName: "pages/psyche/guidance/index" */))
const _416079a1 = () => interopDefault(import('../pages/psyche/huno/index.vue' /* webpackChunkName: "pages/psyche/huno/index" */))
const _7a15b0cc = () => interopDefault(import('../pages/teacher/search.vue' /* webpackChunkName: "pages/teacher/search" */))
const _4ec5958a = () => interopDefault(import('../pages/my/money/fail.vue' /* webpackChunkName: "pages/my/money/fail" */))
const _f624cdc0 = () => interopDefault(import('../pages/my/money/fill.vue' /* webpackChunkName: "pages/my/money/fill" */))
const _3f1c017a = () => interopDefault(import('../pages/my/money/result.vue' /* webpackChunkName: "pages/my/money/result" */))
const _7d1bc8a7 = () => interopDefault(import('../pages/my/money/withdraw.vue' /* webpackChunkName: "pages/my/money/withdraw" */))
const _3d745b33 = () => interopDefault(import('../pages/my/money/withdraw-result.vue' /* webpackChunkName: "pages/my/money/withdraw-result" */))
const _7446bc68 = () => interopDefault(import('../pages/my/order/cancel/index.vue' /* webpackChunkName: "pages/my/order/cancel/index" */))
const _18a236f8 = () => interopDefault(import('../pages/my/profile/child.vue' /* webpackChunkName: "pages/my/profile/child" */))
const _90cf2a1e = () => interopDefault(import('../pages/my/subscription/cancel/index.vue' /* webpackChunkName: "pages/my/subscription/cancel/index" */))
const _3d694c08 = () => interopDefault(import('../pages/my/subscription/confirm.vue' /* webpackChunkName: "pages/my/subscription/confirm" */))
const _249c9102 = () => interopDefault(import('../pages/schedule/cancel/result.vue' /* webpackChunkName: "pages/schedule/cancel/result" */))
const _0cbb90bd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _55b122ea = () => interopDefault(import('../pages/my/reviews/_id/index.vue' /* webpackChunkName: "pages/my/reviews/_id/index" */))
const _07c9e265 = () => interopDefault(import('../pages/product/curation/_id.vue' /* webpackChunkName: "pages/product/curation/_id" */))
const _2d5c07a3 = () => interopDefault(import('../pages/psyche/guidance/_slug.vue' /* webpackChunkName: "pages/psyche/guidance/_slug" */))
const _3fa9d859 = () => interopDefault(import('../pages/psyche/huno/_slug.vue' /* webpackChunkName: "pages/psyche/huno/_slug" */))
const _f86f4798 = () => interopDefault(import('../pages/my/reviews/_id/result.vue' /* webpackChunkName: "pages/my/reviews/_id/result" */))
const _47416bf6 = () => interopDefault(import('../pages/my/order/_type/_id.vue' /* webpackChunkName: "pages/my/order/_type/_id" */))
const _33593efc = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _7d8ee400 = () => interopDefault(import('../pages/event/_slug.vue' /* webpackChunkName: "pages/event/_slug" */))
const _88bf2afa = () => interopDefault(import('../pages/event/_slug/index.vue' /* webpackChunkName: "pages/event/_slug/index" */))
const _54e3c0a8 = () => interopDefault(import('../pages/notice/_id.vue' /* webpackChunkName: "pages/notice/_id" */))
const _500af376 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _4d588508 = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _2748b688 = () => interopDefault(import('../pages/product/_id/payment.vue' /* webpackChunkName: "pages/product/_id/payment" */))
const _e9408300 = () => interopDefault(import('../pages/product/_id/subscribe.vue' /* webpackChunkName: "pages/product/_id/subscribe" */))
const _07451de1 = () => interopDefault(import('../pages/psych/_slug.vue' /* webpackChunkName: "pages/psych/_slug" */))
const _0bb922dc = () => interopDefault(import('../pages/roadmap/_id.vue' /* webpackChunkName: "pages/roadmap/_id" */))
const _7f1980d6 = () => interopDefault(import('../pages/roadmap/_id/index.vue' /* webpackChunkName: "pages/roadmap/_id/index" */))
const _2ec098cc = () => interopDefault(import('../pages/schedule/_hash.vue' /* webpackChunkName: "pages/schedule/_hash" */))
const _372576d4 = () => interopDefault(import('../pages/schedule/_hash/detail.vue' /* webpackChunkName: "pages/schedule/_hash/detail" */))
const _36943b90 = () => interopDefault(import('../pages/teacher/_id.vue' /* webpackChunkName: "pages/teacher/_id" */))
const _ddfbc7fa = () => interopDefault(import('../pages/coupon/_id/usable-product.vue' /* webpackChunkName: "pages/coupon/_id/usable-product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _2febfc6c,
    name: "blog"
  }, {
    path: "/chat",
    component: _33c1ba00,
    name: "chat"
  }, {
    path: "/error",
    component: _5081aef3,
    name: "error"
  }, {
    path: "/event",
    component: _7f458548,
    name: "event"
  }, {
    path: "/introduction",
    component: _1df8cbff,
    name: "introduction"
  }, {
    path: "/maintenance",
    component: _982def84,
    name: "maintenance"
  }, {
    path: "/my",
    component: _13054ad8,
    name: "my"
  }, {
    path: "/notice",
    component: _4a41a380,
    name: "notice"
  }, {
    path: "/private",
    component: _3e109f9e,
    name: "private"
  }, {
    path: "/product",
    component: _1949fec6,
    name: "product"
  }, {
    path: "/psych",
    component: _08fbbf29,
    name: "psych"
  }, {
    path: "/psyche",
    component: _03a8d21c,
    name: "psyche"
  }, {
    path: "/request",
    component: _00f0298c,
    name: "request"
  }, {
    path: "/roadmap",
    component: _2eeb09aa,
    name: "roadmap"
  }, {
    path: "/schedule",
    component: _ab793002,
    name: "schedule"
  }, {
    path: "/search",
    component: _1df9b910,
    name: "search"
  }, {
    path: "/teacher",
    component: _82a1a860,
    name: "teacher"
  }, {
    path: "/auth/leave",
    component: _9169e896,
    name: "auth-leave"
  }, {
    path: "/auth/leave-success",
    component: _2fc7922a,
    name: "auth-leave-success"
  }, {
    path: "/auth/signup",
    component: _180e568a,
    name: "auth-signup"
  }, {
    path: "/auth/success",
    component: _4fd59581,
    name: "auth-success"
  }, {
    path: "/card/fail",
    component: _6ad62fa8,
    name: "card-fail"
  }, {
    path: "/card/result",
    component: _270014a7,
    name: "card-result"
  }, {
    path: "/my/alarm",
    component: _c2eb3e54,
    name: "my-alarm"
  }, {
    path: "/my/authCode",
    component: _f5f868b4,
    name: "my-authCode"
  }, {
    path: "/my/coupon",
    component: _58c85658,
    name: "my-coupon"
  }, {
    path: "/my/favorites",
    component: _8da7c748,
    name: "my-favorites"
  }, {
    path: "/my/friends",
    component: _e1880152,
    name: "my-friends"
  }, {
    path: "/my/money",
    component: _2dc70885,
    name: "my-money"
  }, {
    path: "/my/order",
    component: _23b81593,
    name: "my-order"
  }, {
    path: "/my/profile",
    component: _23a6c0a4,
    name: "my-profile"
  }, {
    path: "/my/reviews",
    component: _166db8c0,
    name: "my-reviews"
  }, {
    path: "/my/subscription",
    component: _180bc7e4,
    name: "my-subscription"
  }, {
    path: "/my/voucher",
    component: _2f41e020,
    name: "my-voucher"
  }, {
    path: "/payment/fail",
    component: _e8eb8228,
    name: "payment-fail"
  }, {
    path: "/payment/result",
    component: _2a253a2a,
    name: "payment-result"
  }, {
    path: "/private/banner",
    component: _52d03246,
    name: "private-banner"
  }, {
    path: "/private/commonTextAreaField",
    component: _135d8f34,
    name: "private-commonTextAreaField"
  }, {
    path: "/private/complete",
    component: _e2f12fac,
    name: "private-complete"
  }, {
    path: "/private/preGuide",
    component: _a1a0e5ec,
    name: "private-preGuide"
  }, {
    path: "/private/privateRequestForm",
    component: _24eddea1,
    name: "private-privateRequestForm"
  }, {
    path: "/private/processGuideModal",
    component: _3578b282,
    name: "private-processGuideModal"
  }, {
    path: "/product/suggest",
    component: _1c89dd8f,
    name: "product-suggest"
  }, {
    path: "/psyche/guidance",
    component: _2f12a8eb,
    name: "psyche-guidance"
  }, {
    path: "/psyche/huno",
    component: _416079a1,
    name: "psyche-huno"
  }, {
    path: "/teacher/search",
    component: _7a15b0cc,
    name: "teacher-search"
  }, {
    path: "/my/money/fail",
    component: _4ec5958a,
    name: "my-money-fail"
  }, {
    path: "/my/money/fill",
    component: _f624cdc0,
    name: "my-money-fill"
  }, {
    path: "/my/money/result",
    component: _3f1c017a,
    name: "my-money-result"
  }, {
    path: "/my/money/withdraw",
    component: _7d1bc8a7,
    name: "my-money-withdraw"
  }, {
    path: "/my/money/withdraw-result",
    component: _3d745b33,
    name: "my-money-withdraw-result"
  }, {
    path: "/my/order/cancel",
    component: _7446bc68,
    name: "my-order-cancel"
  }, {
    path: "/my/profile/child",
    component: _18a236f8,
    name: "my-profile-child"
  }, {
    path: "/my/subscription/cancel",
    component: _90cf2a1e,
    name: "my-subscription-cancel"
  }, {
    path: "/my/subscription/confirm",
    component: _3d694c08,
    name: "my-subscription-confirm"
  }, {
    path: "/schedule/cancel/result",
    component: _249c9102,
    name: "schedule-cancel-result"
  }, {
    path: "/",
    component: _0cbb90bd,
    name: "index"
  }, {
    path: "/my/reviews/:id",
    component: _55b122ea,
    name: "my-reviews-id"
  }, {
    path: "/product/curation/:id?",
    component: _07c9e265,
    name: "product-curation-id"
  }, {
    path: "/psyche/guidance/:slug",
    component: _2d5c07a3,
    name: "psyche-guidance-slug"
  }, {
    path: "/psyche/huno/:slug",
    component: _3fa9d859,
    name: "psyche-huno-slug"
  }, {
    path: "/my/reviews/:id/result",
    component: _f86f4798,
    name: "my-reviews-id-result"
  }, {
    path: "/my/order/:type/:id?",
    component: _47416bf6,
    name: "my-order-type-id"
  }, {
    path: "/blog/:slug",
    component: _33593efc,
    name: "blog-slug"
  }, {
    path: "/event/:slug",
    component: _7d8ee400,
    children: [{
      path: "",
      component: _88bf2afa,
      name: "event-slug"
    }]
  }, {
    path: "/notice/:id",
    component: _54e3c0a8,
    name: "notice-id"
  }, {
    path: "/product/:id",
    component: _500af376,
    children: [{
      path: "",
      component: _4d588508,
      name: "product-id"
    }, {
      path: "payment",
      component: _2748b688,
      name: "product-id-payment"
    }, {
      path: "subscribe",
      component: _e9408300,
      name: "product-id-subscribe"
    }]
  }, {
    path: "/psych/:slug",
    component: _07451de1,
    name: "psych-slug"
  }, {
    path: "/roadmap/:id",
    component: _0bb922dc,
    children: [{
      path: "",
      component: _7f1980d6,
      name: "roadmap-id"
    }]
  }, {
    path: "/schedule/:hash",
    component: _2ec098cc,
    name: "schedule-hash",
    children: [{
      path: "detail",
      component: _372576d4,
      name: "schedule-hash-detail"
    }]
  }, {
    path: "/teacher/:id",
    component: _36943b90,
    name: "teacher-id"
  }, {
    path: "/coupon/:id?/usable-product",
    component: _ddfbc7fa,
    name: "coupon-id-usable-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

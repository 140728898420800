const COMPONENT_NAME = {
  favorite: 'favorite',
  calendar: 'calendar',
  nevMenu: 'nav_menu_button',
  loginBanner: 'login_banner',
  loginCta: 'login_cta',
  likeReview: 'like_review',
  productCta: 'product_cta',
  chatStart: 'chat_start',
  helpful: 'helpful',
  error401: 'error_401',
  introduction: 'introduction',
  authCode: 'auth_code',
  openAlarm: 'open_alarm',
  privateRequest: 'private_request',
  blogCta: 'blog_cta',
};

// 상품 상세페이지
const PRODUCT_SECTION_NAME = {
  summary: 'summary', // 요약
  productSchedule: 'product_schedule', // 스케줄 선택영역
  alarmSchedule: 'alarm_schedule', // 스케줄 정보가 없을때 나오는 알림신청 섹션 버튼

  recommendCategoryAround: 'same_category', // xx주제의 비슷한 수업

  reviewList: 'review', // 상품 정보 아래 리뷰 리스트

  teacherRelated: 'teacher_related', // 상품 정보 아래 xx선생님의 다른 수업 큐레이션
  ageAround: 'class_age', // 상품 정보 아래 ~세 연령 추천 수업 큐레이션
  random: 'random', // 상품 정보 아래 다양하고 재미있는 수업 큐레이션

  productFooter: 'product_footer', // 결제 가능한 수업의 페이지 내에서 하단에 픽스로 붙어있는 결제 버튼
  alarmFooter: 'alarm_footer', // 수업의 페이지 내에서 하단에 픽스로 붙어있는 알림신청 버튼
  surveyFooter: 'suggestion_footer', // 수요 조사중인 상품 하단 픽스로 붙어있는 듣고싶어요 영역 자체 섹션
  survey: 'survey', // 수요 조사중인 상품 하단 픽스로 붙어있는 듣고싶어요 버튼
};

// 선생님 상세페이지
const TEACHER_SECTION_NAME = {
  oneday: 'oneday',
  subscription: 'subscribe',
  group: 'multiple',
  TEACHER_SCHEDULE: 'teacher_schedule',
};

// 신규 홈
const NEW_HOME_SECTION_NAME = {
  HERO_BANNER: 'hero_banner', // 히어로 배너
  TRENDING: 'weekly_popular', // 재능 > 이번주 인기 급상승
  REVIEW: 'home_review', // 재능 > 친구들이 남긴 후기
  FIRST_PICK: 'first_pick', // 재능 > 후회없는 첫 구매 선택
  TALENT_CATEGORY: 'talent_category', // 재능 > 친구들이 많이 찾는 테마
  SUGGESTION: 'suggestion', // 재능 & 학습 > 수요조사
  SHORT_TERM: 'short_term', // 단기 완성, 주요 요점을 골라서
  LONG_TERM: 'long_term', // 중장기 완성, 탄탄한 성장을 설계하며
  EDUCATION_CATEGORY: 'education_category', // 학습 > 꾸그의 모든 수업을 만나보세요
  BLOG_CONTENT: 'blog_content', // 홈 화면에 있는 블로그 큐레이션
  BEST_TEACHER: 'best_teacher', // 베스트 선생님 큐레이션
};

const BLOG_SECTION_NAME = {
  PRICE_CATEGORY: 'price_category', // 가격대별 추천수업 큐레이션
};

const DRAWER_SECTION = {
  DRAWER_BANNER: 'drawer_banner',
  DRAWER_MENU: 'drawer_menu',
  FAVORITE_PRODUCT: 'favorite_product', // 서랍 메뉴 > 찜한 수업
  FAVORITE_ROADMAP: 'favorite_roadmap', // 서랍 메뉴 > 찜한 로드맵
};

const SEARCH_INITIATE = {
  NAVIGATION: 'navigation',
  TAB_BAR: 'tabbar',
};

// 로드맵 관련 섹션명
const ROADMAP_SECTION_NAME = {
  MAIN: 'home_main_roadmap', // 홈 > 목표달성 추천 로드맵
  ONGOING: 'home_ongoing_roadmap', // 홈 > 듣고 있는 수업이 포함된 로드맵
  DETAIL: 'product_detail_roadmap', // 수업 상세페이지 > 이 수업이 포함된 로드맵
  SCHEDULE: 'schedule_detail_roadmap', // 스케줄 상세페이지 > 이 수업이 포함된 로드맵
  REVIEW: 'after_review_roadmap', // 후기 등록 완료 후 > 다음 수업이 고민된다면
  RELATED: 'roadmap_related_class', // 로드맵 상세 > 로드맵에 포함된 수업
  ALL: 'roadmap_all_list', // 로드맵 전체 보기 섹션
};

Object.freeze(COMPONENT_NAME);
Object.freeze(PRODUCT_SECTION_NAME);
Object.freeze(TEACHER_SECTION_NAME);
Object.freeze(SEARCH_INITIATE);
Object.freeze(DRAWER_SECTION);
Object.freeze(NEW_HOME_SECTION_NAME);
Object.freeze(BLOG_SECTION_NAME);
Object.freeze(ROADMAP_SECTION_NAME);

export {
  BLOG_SECTION_NAME,
  COMPONENT_NAME,
  DRAWER_SECTION,
  NEW_HOME_SECTION_NAME,
  PRODUCT_SECTION_NAME,
  ROADMAP_SECTION_NAME,
  SEARCH_INITIATE,
  TEACHER_SECTION_NAME,
};


/**
 * @description
 * 홈 > 히어로 배너
 */

import { mapActions } from 'vuex';
import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeNames.js';
import { getLastHomeType } from '@/utils/cookieUtils';

import HeroBannerSwiper from './HeroBannerSwiper.vue';
import HeroBannerSkeleton from './HeroBannerSkeleton.vue';

export default {
  name: 'HeroBanner',
  components: { HeroBannerSwiper, HeroBannerSkeleton },
  data() {
    return {
      isTalentPage: null,
      bannerList: [],
    };
  },
  fetchOnServer: false,
  async fetch() {
    const lastHomeType = getLastHomeType();
    const isTalentPage =
      (this.$route.query?.tab === undefined && lastHomeType !== 'education' )||
      this.$route.query?.tab === 'talent';

    try {
      // 페이지에서 tab 쿼리가 변경되었다면 다시 패치합니다.
      if (this.isTalentPage === null || this.isTalentPage !== isTalentPage) {
        this.isTalentPage = isTalentPage;
        this.bannerList = await this.getBannerList({ isTalent: isTalentPage });
      }
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    heroSectionName: () => NEW_HOME_SECTION_NAME.HERO_BANNER,
  },
  watch: {
    // route변경이 일어나면 일단 fetch로 보냅니다.
    $route() {
      this.$fetch();
    },
  },
  methods: {
    ...mapActions('new-home', ['getBannerList']),
  },
};

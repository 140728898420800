
import { DOM_ID_LIST } from '@/utils/enums.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import MenuList from '@/components/common/nav/MenuList.vue';
import NavLogo from '@/components/common/nav/NavLogo.vue';
import SearchBar from '@/components/common/nav/SearchBar.vue';
import AppDrawer from '@/components/layout/AppDrawer';

export default {
  name: 'NavigationBar',
  components: { NavLogo, SearchBar, MenuList, AppDrawer },
  data() {
    return {
      isDrawer: false,
      before: 0,
      headerShadow: false,
    };
  },
  computed: {
    ...mapGetters(['getIsHiddenNavHeader', 'activeMobileCategoryListModal']),
    navigationId: () => DOM_ID_LIST.NAV,
    navClassList() {
      const returnClassList = [];

      const pathName = this.$route.name;

      // 검색 & 상품 상세페이지 & 로드맵 상세페이지 내에서는 헤더 그림자 스타일이 적용되지 않게
      if (
        this.headerShadow &&
        !['search', 'product-id', 'roadmap-id'].includes(pathName)
      )
        returnClassList.push('header-shadow');

      if (this.$auth.loggedIn) {
        // 로그인
        // 스크롤이 아래로 갔다면 네비를 숨기는 class 추가
        if (this.getIsHiddenNavHeader) returnClassList.push('hide-nav');
      } else if (
        ['search', 'teacher-id', 'product-id'].includes(this.$route.name) &&
        this.getIsHiddenNavHeader
      ) {
        // 비로그인
        // (search페이지 & 선생님 상세페이지 & 상품 페이지) 에서 스크롤이 아래로 갔다면 네비를 숨김

        returnClassList.push('hide-nav');
      }

      if (this.isDrawer) returnClassList.push('active-drawer');

      return returnClassList.join(' ');
    },
  },
  watch: {
    $route() {
      this.closeDrawer();
    },
    '$auth.loggedIn'() {
      this.setPersonalizedProducts();
    },
  },
  mounted() {
    this.setPersonalizedProducts();
    // <header>가 scrollUp될 때, position: sticky로 수정하고, transition되도록 함.
    addEventListener('scroll', this.scrollEvent);
  },
  methods: {
    ...mapActions('user/product', ['selectUserMyProductList']),
    ...mapActions('user/review', ['getHelpfulReviewIds']),
    ...mapActions('user/favorite', [
      'selectFavoriteProductIds',
      'selectFavoriteCourseIds',
    ]),
    ...mapMutations(['setIsHiddenNavHeader']),
    ...mapMutations({
      resetAllUserProduct: 'user/product/RESET_ALL_USER_PRODUCT',
      resetFavoriteProduct: 'user/favorite/RESET_FAVORITE_PRODUCTS',
      resetHelpfulReviewIds: 'user/review/RESET_HELPFUL_REVIEW_IDS',
    }),
    setPersonalizedProducts() {
      if (this.$auth.loggedIn) {
        // 참여한 수업 표기
        this.selectUserMyProductList();

        // 찜바구니 수업 표기
        this.selectFavoriteProductIds();
        this.selectFavoriteCourseIds();

        // 도움이 됐어요 누른 ids 가져오기
        this.getHelpfulReviewIds();
      } else {
        // 로그아웃시 전체 초기화
        this.resetAllUserProduct();
        this.resetFavoriteProduct();
        this.resetHelpfulReviewIds();
      }
    },
    closeDrawer() {
      this.isDrawer = false;
    },
    toggleDrawer() {
      this.isDrawer = !this.isDrawer;
    },
    /**
     * @description 웹 네비게이션 헤더에서 아래로 스크롤하면 헤더 숨김. 위로 스크롤 하면 헤더 나타냄
     */
    scrollEvent() {
      // 스크롤이 60(헤더 모바일 높이정도)보다 적을때는 헤더는 늘 보입니다
      if (window?.scrollY < 60) {
        this.setIsHiddenNavHeader(false);
        this.headerShadow = false;
        return;
      }
      this.headerShadow = true;

      if (window.scrollY <= 0 || window.scrollY >= window?.scrollHeight) return;

      if (this.before < window.scrollY) {
        // 스크롤 내릴 때: 헤더가 viewport에서 사라짐
        this.$route.name !== 'chat' && this.setIsHiddenNavHeader(true);
      } else {
        // 스크롤 올릴 때: 헤더가 viewport에 나타나야 함.
        this.setIsHiddenNavHeader(false);
      }
      this.before = scrollY;
    },
  },
};


/**
 * @description
 * 홈 > 레이아웃 > 다가오는 수업
 */

import { encodeHash } from '@/utils/hash.js';
import { mapActions } from 'vuex';
import { addMinutes, isAfter } from 'date-fns';
import { ATTEND_CLASS } from '@/utils/amplitudeParams.js';

import Icon from '@/components/common/icon/Icon.vue';
import Thumbnail from '@/components/common/img/Thumbnail.vue';
import UpcomingClassStartTimer from './UpcomingClassStartTimer.vue';

export default {
  name: 'Upcoming',
  components: { Icon, Thumbnail, UpcomingClassStartTimer },
  data() {
    return {
      isShowUpcoming: false,
      isJoinZoomApiPending: false,
      upcomingSchedule: null,
    };
  },
  async fetch() {
    try {
      const res = await this.getUpcomingSchedule();
      this.upcomingSchedule = res?.sch;
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    product: ({ upcomingSchedule }) => upcomingSchedule?.product,
    childrenName: ({ upcomingSchedule }) =>
      upcomingSchedule?.userProducts[0]?.children?.name,
    classDate: ({ upcomingSchedule }) => upcomingSchedule?.classDate,
    classTimeStart: ({ upcomingSchedule }) => upcomingSchedule?.classTimeStart,
    classTimeEnd: ({ upcomingSchedule }) => upcomingSchedule?.classTimeEnd,
    hashCode: ({ upcomingSchedule }) => {
      if (!upcomingSchedule || !upcomingSchedule?.product) return '';

      const { classType } = upcomingSchedule.product;

      // 구독: 0, 원데이: 1, 다회차 | 꾸러미: 2, 구독: subId, 그외: 스케줄id
      const classCode =
        classType === '롤링 클래스' ? 0 : classType === '원데이 클래스' ? 1 : 2;
      return encodeHash(classCode, upcomingSchedule.id);
    },
    scheduleId: ({ upcomingSchedule }) => upcomingSchedule?.id,
  },
  methods: {
    ...mapActions('user/schedule', ['getUpcomingSchedule']),
    ...mapActions(['receiveServerTime', 'openSimpleAlert']),
    ...mapActions('product/hash', ['createZoomAndAttendance']),
    showUpcoming() {
      this.isShowUpcoming = true;
    },
    async onClickJoinZoom() {
      if (this.isJoinZoomApiPending) return;

      try {
        this.isJoinZoomApiPending = true;

        const nowTime = await this.receiveServerTime();
        const classEndTime = new Date(
          `${this.classDate}T${this.classTimeEnd}:00+09:00`,
        );

        if (isAfter(nowTime, addMinutes(classEndTime, 30))) {
          this.openSimpleAlert('종료된 수업입니다.');
          return;
        }

        const res = await this.createZoomAndAttendance({
          scheduleId: this.scheduleId,
        });

        if (res.error) {
          this.openSimpleAlert('오류가 발생했습니다.');
          return;
        }

        // 전부 통과해서 joinUrl이 있다면 오픈
        setTimeout(() => {
          // log남기기
          this.logUserEvent('click_attend_class', {
            product_id: this.product?.id || undefined,
            teacher_id: this.product?.teacher?.id || undefined,
            class_type: this.product?.classType || undefined,
            at: ATTEND_CLASS.HOME,
          });
          this.setAmplitudeUserPropertiesOnce({
            first_attended_date: new Date().toISOString(),
          });

          // safari에서 비동기로 window.open 호출 시 팝업창 차단을 해서 setTimeout을 걸어 동기방식으로 호출하게 수정
          window.open(res.joinUrl);
        });
      } catch (e) {
        console.error(e);
        this.openSimpleAlert(
          e.response?.data?.data?.error ||
            '오류가 발생했습니다. 관리자에게 문의해주세요',
        );
      } finally {
        this.isJoinZoomApiPending = false;
      }
    },
  },
};


import { COMPONENT_NAME } from '@/utils/amplitudeNames';
import { NAV_MENU } from '@/utils/amplitudeParams.js';

export default {
  name: 'UnAuthProfile',
  computed: {
    drawerProp: () => NAV_MENU.DRAWER,
  },
  methods: {
    kakaoRequestToken() {
      this.logSignUpEvent(this.drawerProp);
      // requestToken: kakaoAuth 플러그인 안에 글로벌 메서드로 있음
      this.requestToken({ componentName: COMPONENT_NAME.loginCta });
    },
  },
};

import * as filterOptions from '@/utils/filterOptions';
import * as filterUtils from '@/utils/filterUtils';

export const initMeta = {
  currentPage: 0,
  itemCount: 0,
  itemsPerPage: 20,
  totalItems: 0,
  totalPages: 0,
};
const initState = {
  searchList: [],
  searchListMeta: null,
  isLoading: false,
  buttonTypeFromProduct: undefined, // 'set_schedule' || 'same_schedule' || undefined
  activeBottomDrawerFilter: '', // 'schedule' || 'age' || 'multi'
  hasViewFilter: false, // 검색 필터에 보이는 버튼들이 있는지. <searchListSection /> 컴포넌트 margin-top 판단용.
};
export const state = () => Object.assign({}, initState);

export const getters = {
  // 검색 결과
  searchList(state) {
    return state.searchList;
  },
  // 총 검색 결과 갯수
  totalSearchCount(state) {
    return state.searchListMeta?.totalItems || 0;
  },
  // 현재 페이지
  currentPage(state) {
    return state.searchListMeta?.currentPage || 0;
  },
  // 총 페이지 수, 무한 스크롤 용
  totalPages(state) {
    return state.searchListMeta?.totalPages || 0;
  },
  // 검색 결과 없음
  isEmptySearchResult(state) {
    return state.searchListMeta?.totalItems === 0;
  },
  isLoading: state => {
    return state.isLoading;
  },
  buttonTypeFromProduct: state => state.buttonTypeFromProduct,
  hasViewFilter: state => state.hasViewFilter,
  activeBottomDrawerFilter: state => state.activeBottomDrawerFilter,
  isDateNAgeFilterActive: state => {
    return (
      state.activeBottomDrawerFilter === 'schedule' ||
      state.activeBottomDrawerFilter === 'age'
    );
  },
};

export const actions = {
  /**
   * @description
   * 검색 결과 없을 때 사용.
   */
  async fetchNofilterSearchList(store, meta) {
    try {
      const { data } = await this.$axios.$get(`/v2.1/search`, {
        params: {
          listType: 'POPULARITY',
          page: meta?.currentPage + 1 || 1,
          limit: meta.itemsPerPage,
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async fetchSearchList({ state, commit }, query) {
    if (state.isLoading) return;
    commit('SET_IS_LOADING', true);

    const queryParams = filterUtils.apiQueryMaker({
      keyword: query?.keyword || '',
      age: query?.selectedAge?.split(',').map(a => parseInt(a)) || [],
      selectedDayOfWeek:
        query?.selectedDayOfWeek?.split(',').map(a => parseInt(a)) || [],
      selectedPeriodOption: query?.selectedPeriodOption,
      category: query?.selectedSubCategory || query?.selectedCategory,
      classType:
        query?.selectedClassType?.split(',').map(a => parseInt(a)) || [],
      price: query?.selectedPrice
        ? filterOptions.PRICE_OPTIONS[query?.selectedPrice]
        : null,
      order: query?.order,
      startTimeIdx: query?.startTimeIdx,
      endTimeIdx: query?.endTimeIdx,
      difficulty: query?.difficulty,
      onlyDiscounted: query?.onlyDiscounted,
      classStartDate: query?.classStartDate, // 2023-01-01
      classEndDate: query?.classEndDate, // 2023-01-01
    });

    try {
      const { data } = await this.$axios.$get(`/v2.1/search`, {
        params: {
          ...queryParams,
          listType: query?.order || query?.listType,
          page: state.searchListMeta?.currentPage + 1 || 1,
          limit: initMeta.itemsPerPage,
        },
      });
      commit('SET_SEARCH_LIST_META', data.meta);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  /**
   * @description
   * 구독만 취소 했을때 보여주는 큐레이션용 간단한 검색 api
   */
  async searchSubsProduct() {
    try {
      const { data } = await this.$axios.$get(`/v2.1/search`, {
        params: {
          selectedClassType: 2,
          order: `POPULARITY`,
          page: 1,
          limit: 10,
        },
      });
      return data.items || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};

export const mutations = {
  SET_SEARCH_LIST(state, list) {
    state.searchList = list;
  },
  SET_SEARCH_LIST_META(state, meta) {
    state.searchListMeta = meta;
  },
  // state 모두 초기화
  RESET_SEARCH(state) {
    Object.assign(state, { ...initState });
  },
  // flag = 'set_schedule' || 'same_schedule' || undefined
  SET_BUTTON_TYPE_FROM_PRODUCT(state, flag) {
    state.buttonTypeFromProduct = flag;
  },
  SET_HAS_ViEW_FIlTER(state, bool) {
    state.hasViewFilter = bool;
  },
  SET_IS_LOADING(state, bool) {
    state.isLoading = bool;
  },
  // activeFilter = 'schedule' || 'age' || 'multi'
  SET_ACTIVE_BOTTOM_DRAWER_FILTER(state, activeFilter) {
    state.activeBottomDrawerFilter = activeFilter;
  },
};

/* eslint-disable */
export default function ({ route, redirect }) {
  if (typeof route.query.utm_aligo !== 'undefined') {
    return redirect(
      `${
        route.path
      }?utm_source=lms&utm_medium=message&utm_campaign=op-notice&utm_term=registered&utm_content=${
        route.params.id || 'home'
      }`,
    );
  }

  // fullPath 사이에 utm_aligo가 붙으면 다른 utm으로 replace한 주소로 리다이렉트
  if (route.fullPath.includes('utm_aligo')) {
    return redirect(
      `${route.fullPath.replace(
        '%2F%3Futm_aligo',
        `&utm_source=lms&utm_medium=message&utm_campaign=op-notice&utm_term=registered&utm_content=${
          route.params.id || 'home'
        }`,
      )}`,
    );
  }
}


import { DRAWER_SECTION } from '@/utils/amplitudeNames.js';
import { getPathAt } from '@/utils/path.js';
import { mapActions, mapGetters } from 'vuex';

import ProgressSpinner from '@/components/common/ProgressSpinner';
import LinkButton from '@/components/common/btn/LinkButton';
import CourseItem from '@/components/course/course-item/CourseItem.vue';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'FavoriteCourseList',
  components: {
    InfiniteLoading,
    ProgressSpinner,
    CourseItem,
    LinkButton,
  },
  props: {
    isDrawer: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      DRAWER_SECTION,
      infiniteId: +new Date(),
      currentPage: 1,
      totalPages: 2,
      itemsPerPage: 20,
      totalItems: 0,
    };
  },
  async fetch() {
    try {
      const res = await this.selectFavoriteCourseList({
        page: this.currentPage,
        size: this.itemsPerPage,
      });
      this.currentPage = parseInt(res.currentPage) + 1;
      this.totalPages = res.totalPages;
      this.itemsPerPage = parseInt(res.itemsPerPage);
      this.totalItems = res.totalItems;
    } catch (e) {
      console.error(e);
      if (this.$refs.infiniteFavoriteCourseList) {
        this.$refs.infiniteFavoriteCourseList.stateChanger.error();
      }
    }
  },
  fetchOnServer: false,
  computed: {
    ...mapGetters('user/favorite', ['favoriteCourseList']),
  },
  watch: {
    isDrawer(v) {
      if (v) {
        this.infiniteId++;
      }
    },
  },
  methods: {
    ...mapActions('user/favorite', ['selectFavoriteCourseList']),
    infiniteHandler($state) {
      if (this.currentPage <= this.totalPages) {
        this.$fetch().then(() => {
          if (!this.favoriteCourseList.length) {
            $state.complete();
          } else {
            $state.loaded();
          }
        });
      } else {
        $state.complete();
      }
    },
    logEvent({ id, title, categoryName, progress }) {
      this.logUserEvent('click_roadmap', {
        roadmap_id: id,
        roadmap_name: title,
        roadmap_category_name: categoryName,
        at: getPathAt(this.$route),
        section: DRAWER_SECTION.FAVORITE_ROADMAP,
        is_participate: progress === undefined ? false : progress > 0,
      });
    },
  },
};

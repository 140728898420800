
/**
 * @description
 * 신규 홈 화면 > 최초 진입 시 보여지는 스위치 설명 툴팁
 */

import { Dropdown } from 'floating-vue';
import './TooltipWrapper.vue';

export default {
  name: 'SwitchTooltip',
  components: { Dropdown },
  data() {
    return {
      pinIcon: require('@/assets/img/home/pin.svg'),
    };
  },
};

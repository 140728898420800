
import { stringToNumber } from '@/utils/typeUtils.js';
import { getPathAt } from '@/utils/path';
import { mapActions, mapGetters } from 'vuex';
import { COMPONENT_NAME } from '@/utils/amplitudeNames';
import { DRAWER_SECTION } from '@/utils/amplitudeNames.js';

import LinkButton from '@/components/common/btn/LinkButton';
import InfiniteLoading from 'vue-infinite-loading';
import ProgressSpinner from '@/components/common/ProgressSpinner';
import ProductItem from '@/components/product-item/ProductItem.vue';

export default {
  name: 'FavoriteClassList',
  components: {
    InfiniteLoading,
    ProgressSpinner,
    ProductItem,
    LinkButton,
  },
  props: {
    isDrawer: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      DRAWER_SECTION,
      infiniteId: +new Date(),
      currentPage: 1,
      totalPages: 2,
      itemsPerPage: 20,
      totalItems: 0,
    };
  },
  async fetch() {
    try {
      const res = await this.selectFavoriteList({
        page: this.currentPage,
        size: this.itemsPerPage,
      });
      this.currentPage = parseInt(res.currentPage) + 1;
      this.totalPages = res.totalPages;
      this.itemsPerPage = parseInt(res.itemsPerPage);
      this.totalItems = res.totalItems;
    } catch (e) {
      console.error(e);
      if (this.$refs.infiniteFavoriteProductList) {
        this.$refs.infiniteFavoriteProductList.stateChanger.error();
      }
    }
  },
  fetchOnServer: false,
  computed: {
    ...mapGetters('user/favorite', ['favoriteProductList']),
    ...mapGetters('user/product', ['myProductList']),
    openAlarmProp: () => COMPONENT_NAME.openAlarm,
  },
  watch: {
    isDrawer(v) {
      if (v) {
        this.infiniteId++;
      }
    },
  },

  methods: {
    ...mapActions('user/favorite', ['selectFavoriteList', 'toggleAlarm']),
    logEvent(product) {
      const { id, ratingCount, classType, teacher, productStatus } = product;

      const isEnrolled =
        this.$auth.loggedIn && id > -1 && this.myProductList.includes(id);

      this.logUserEvent('click_product', {
        section: DRAWER_SECTION.FAVORITE_PRODUCT,
        product_id: id,
        at: getPathAt(this.$route),
        class_review_cnt: ratingCount,
        teacher_review_cnt: teacher && teacher.ratingCount,
        teacher_id: teacher && teacher.id,
        class_type: classType,
        is_enrolled: isEnrolled,
        product_status: productStatus,
      });
    },
    infiniteHandler($state) {
      if (this.currentPage <= this.totalPages) {
        this.$fetch().then(() => {
          if (!this.favoriteProductList.length) {
            $state.complete();
          } else {
            $state.loaded();
          }
        });
      } else {
        $state.complete();
      }
    },
    async onClickAlarmToggleButton(favorite) {
      if (!this.$auth.loggedIn) {
        this.requestToken({});
      } else if (favorite && favorite.product) {
        try {
          const res = await this.toggleAlarm({
            productId: favorite?.product?.id,
          });

          // 알림신청 성공시 토스트 + 알림
          if (res.isOnAlarm) {
            this.$toast.open({
              message:
                '<p class="font-caption-bold">스케줄 오픈 알림이 등록되었습니다.</p><p class="font-caption">새로운 스케줄 오픈 시 알림을 보내드릴게요!</p>',
              type: 'default',
              duration: 2000,
            });

            this.logUserEvent('add_alarm', {
              at: getPathAt(this.$route),
              product_id: stringToNumber(favorite?.product?.id),
              component: 'favorites_setting',
              class_type: favorite.product.classType,
              product_status: favorite.product?.productStatus,
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
};

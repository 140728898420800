
import { mapActions, mapGetters } from 'vuex';
import { COMPONENT_NAME, DRAWER_SECTION } from '@/utils/amplitudeNames';

import AuthProfile from '@/components/layout/drawer/AuthProfile';
import UnAuthProfile from '@/components/layout/drawer/UnAuthProfile';
import DrawerMenu from '@/components/layout/drawer/DrawerMenu';
import Notice from '@/components/layout/drawer/Notice';
import ExpansionSection from '@/components/layout/drawer/ExpansionSection';

export default {
  name: 'AppDrawer',
  components: {
    AuthProfile,
    UnAuthProfile,
    DrawerMenu,
    Notice,
    ExpansionSection,
  },
  props: {
    isDrawer: { type: Boolean, required: false, default: false },
  },
  async fetch() {
    this.getProductCategoryList();

    await this.selectNoticeList({ page: 1, limit: 5 });

    if (this.$auth.loggedIn) {
      // 꾸그 머니 조회
      this.selectUserMoneyStatus();
      this.getReviewReward();
    }
  },
  fetchOnServer: false,
  computed: {
    ...mapGetters('notice', ['noticeList']),
    drawerStyle() {
      if (this.isDrawer) {
        return {
          transform: 'translateY(0)',
        };
      } else {
        return {
          transform: 'translateY(-110%)',
        };
      }
    },
    drawerMenuProp: () => DRAWER_SECTION.DRAWER_MENU,
  },
  watch: {
    '$auth.loggedIn'() {
      this.$fetch();
    },
    isDrawer(v) {
      if (v) {
        this.$fetch();
        const el = this.$el.querySelector('.drawer__container');
        el.scrollTop = 0;
      }
    },
  },
  methods: {
    ...mapActions('user/money', ['selectUserMoneyStatus']),
    ...mapActions('notice', ['selectNoticeList']),
    ...mapActions('user/review', ['getReviewReward']),
    ...mapActions('filter', ['getProductCategoryList']),
    kakaoRequestToken({ componentName = undefined } = {}) {
      // requestToken: kakaoAuth 플러그인 안에 글로벌 메서드로 있음
      this.requestToken({ componentName });
    },
    onClickNavMenu({ type = null, requiredLoggedIn = true } = {}) {
      this.logUserEvent('click nav-menu', { 'button id': type });

      if (requiredLoggedIn && !this.$auth.loggedIn) {
        this.kakaoRequestToken({ componentName: COMPONENT_NAME.navMenu });
      } else {
        this.onForceClose();
      }
    },
    onForceClose() {
      this.$emit('force-close');
    },
  },
};


/**
 * @description
 * 카테고리 모달에 띄우는 뎁스 안에 하나의 아이템
 * 전체보기의 경우 depthId가 넘어오지 않습니다.
 */

export default {
  name: 'DepthItem',
  props: {
    categoryId: { type: Number, required: true },
    categoryName: { type: String, default: null },
    depthId: { type: Number, default: -1 }, // api응답값을 넣고 쿼리에 실어서 보낼것
    depthTitle: { type: String, default: null },
  },
  computed: {
    itemTitle() {
      return this.depthId > 0 ? this.depthTitle : '전체';
    },
    searchQuery: ({ categoryId, depthId }) => {
      if (depthId > -1) {
        return {
          selectedCategory: categoryId,
          selectedSubCategory: depthId,
          order: 'POPULARITY',
        };
      }

      return {
        selectedCategory: categoryId,
        order: 'POPULARITY',
      };
    },
  },
  methods: {
    logClickDepthCategory() {
      this.logUserEvent('click_category', {
        main_category_id: this.categoryId,
        main_category_name: this.categoryName,
        sub_category_id: this.depthId > 0 ? this.depthId : null,
        sub_category_name: this.depthId > 0 ? this.depthTitle : null,
      });
    },
  },
};


/**
 * @description
 * 홈 화면 > 히어로 배너 슬라이드 아이템
 */

import { HOME_TYPE } from '@/utils/amplitudeParams.js';

import 'swiper/css/swiper.css';
import { SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'HeroBannerSlide',
  components: { SwiperSlide },
  props: {
    goTo: { type: String, default: '/' },
    sequence: { type: Number, required: true },
    slug: { type: String, default: null },
    text: { type: String, default: '' },
    imgMain: { type: String, default: '' },
    imgDesktopMain: { type: String, default: '' },
    imgBg: { type: String, default: '' },
    bgColor: { type: String, default: '#FFFFFF' },
  },
  methods: {
    logBannerEvent() {
      const isTalentPage =
        this.$route.query?.tab === undefined ||
        this.$route.query?.tab === 'talent';

      this.logUserEvent('click_event_banner', {
        sequence: this.sequence,
        event_id: this.slug,
        home_type: isTalentPage ? HOME_TYPE.TALENT : HOME_TYPE.EDUCATION,
      });
    },
  },
};

import { convertV3ClassToV2Product } from '@/utils/convertV3ToV2.js';
import { PRODUCT_STATUS } from '@/utils/enums.js';

// 홈 화면 공용 스토어
const initState = () => {
  return {
    //
  };
};

export const state = () => initState();

export const getters = {
  //
};

export const actions = {
  /**
   * @description
   * 홈 > 배너 리스트 받아와 내려줍니다.
   */
  async getBannerList(_, { isTalent }) {
    try {
      const data = await this.$axios.$get(`/v2/event/banner`, {
        params: { isTalent },
      });

      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 홈 > 재능 & 학습 별 키워드 리스트
   */
  async getKeywordList(_, { isTalent }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/keyword`,
        {
          params: { isTalent },
        },
      );

      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },

  /**
   * @description
   * 홈 > 재능 & 학습 별 베스트 선생님
   */
  async getBestTeacherList(_, { isTalent = false }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/teacher/best`,
        {
          params: { isTalent },
        },
      );
      return data || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 홈 > 재능 & 학습 별 가중치 높은 큐레이션 순으로 들고오논 api
   */
  async getFixedCurationList(_, { isTalent = false, size = 1 }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/curation`,
        { params: { isTalent, size } },
      );

      return data.reduce((filteredCurationList, cur) => {
        if (!cur.curationProducts || cur.curationProducts.length === 0)
          return filteredCurationList;

        return filteredCurationList.concat({
          ...cur,
          curationProducts: convertV3ClassToV2Product(cur.curationProducts),
        });
      }, []);
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 홈 > 재능 & 학습 별 수요조사 상품 리스트
   */
  async getSurveyList(_, { page, limit, isTalent }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/survey`,
        {
          params: {
            page,
            size: limit,
            isTalent,
          },
        },
      );

      // 데이터 가공. productStatus를 수요조사로 세팅해줍니다.
      return {
        ...data,
        items: convertV3ClassToV2Product(data.items).map(p => ({
          ...p,
          productStatus: PRODUCT_STATUS.SURVEY,
        })),
      };
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};

export const mutations = {
  //
};

import { render, staticRenderFns } from "./FavoriteClassList.vue?vue&type=template&id=4856d506&scoped=true"
import script from "./FavoriteClassList.vue?vue&type=script&lang=js"
export * from "./FavoriteClassList.vue?vue&type=script&lang=js"
import style0 from "./FavoriteClassList.vue?vue&type=style&index=0&id=4856d506&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4856d506",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductItem: require('/home/runner/work/gguge.web/gguge.web/components/product-item/ProductItem.vue').default})


import CustomerService from '@/components/layout/footer/CustomerService.vue';
import DownloadApp from '@/components/layout/footer/DownloadApp.vue';
import FooterPartner from '@/components/layout/footer/FooterPartner.vue';
import FooterService from '@/components/layout/footer/FooterService.vue';
import SocialLink from '@/components/layout/footer/SocialLink.vue';

export default {
  name: 'AppFooterNew',
  components: {
    FooterService,
    DownloadApp,
    CustomerService,
    SocialLink,
    FooterPartner,
  },
  props: {
    grayBackground: { type: Boolean, default: false },
  },
  data() {
    return {
      corpInfoText:
        '사업자 정보: (주)글로랑ㅣglorang.comㅣ대표 황태일ㅣ개인정보보호 책임자 강성범ㅣ서울특별시 강남구 테헤란로 27길 18 6층ㅣ대표전화 1544-9917ㅣ대표메일 info@glorang.comㅣ사업자등록번호 596-81-00999ㅣ통신판매업신고 2019-서울강남-01817ㅣ(주)글로랑은 통신판매중개자이며 통신판매의 당사자가 아닙니다. 따라서 (주)글로랑은 상품, 거래 정보 및 거래에 대하여 책임을 지지 않습니다.',
    };
  },
  computed: {
    isGrayBg() {
      return ['product-id', 'roadmap-id'].includes(this.$route.name);
    },
  },
};

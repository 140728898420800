import * as filterOptions from '@/utils/filterOptions';

const initState = {
  searchDataLists: [
    // {
    //   queryKey: '쿼리키',
    //   productList: [],
    //   meta: {
    //     currentPage: 0,
    //     itemCount: 0,
    //     itemsPerPage: 10,
    //     totalItems: 0,
    //     totalPages: 0,
    //   },
    // },
  ],
  loadState: {
    pending: false,
    error: false,
    errorMessage: '',
  },
  categoryList: [],
  categoryDifficultyMap: {},
  hasCategoryDifficulty: false,
};

export const state = () => Object.assign({}, initState);

export const getters = {
  loadState: state => state.loadState,
  categoryList: state => state.categoryList,
  categoryDifficultyMap: state => state.categoryDifficultyMap,
  hasCategoryDifficulty: state => state.hasCategoryDifficulty,
};
export const actions = {
  async getProductCategoryList({ commit }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/category`,
      );

      commit('SET_CATEGORY_LIST', data);
      commit('category/SET_CATEGORY_LIST', data);
    } catch (e) {
      console.error(e);
    }
  },
  async getCategoryDifficultyList({ commit }, categoryId) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/category/${categoryId}/difficulty`,
      );
      commit('SET_CATEGORY_DIFFICULTY_MAP', { categoryId, data });
    } catch (e) {
      console.error(e);
    }
  },
};

export const mutations = {
  SET_LOAD_STATE(state, { pending, error, errorMessage }) {
    state.loadState = {
      pending,
      error,
      errorMessage,
    };
  },
  SET_CATEGORY_LIST(state, res) {
    filterOptions.INIT_CATEGORY.categoryOptions = res;
    state.categoryList = res;
  },
  SET_CATEGORY_DIFFICULTY_MAP(state, { categoryId, data }) {
    state.categoryDifficultyMap = {
      ...state.categoryDifficultyMap,
      [categoryId]: data,
    };
  },
};

const LOCAL_STORAGE_KEY = {
  // 앱에서 웹뷰로 열렸는지 체크
  IS_APP: 'isApp',
  // default.vue 등 레이아웃 마지막에 달려있는 클릭 캡쳐 이벤트로
  // data-ap-section에 이름이 정의된 컴포넌트를 눌렀을 시 누른 컴포넌트명을 저장
  LAST_CLICK_SECTION: 'lastClickSection',
  // 오늘 참여 가능한 수업 툴팁 on/off 플래그
  IMMEDIATE_TOOLTIP: 'immediateToolTip',
  // url에 쿼리로 추천인 코드를 받아온적이 있다면 저장하는 데이터
  RECOMMEND_CODE: 'recommendCode',
  // 마지막으로 읽은 알림센터의 id를 기록해 이전 메시지 표기에 사용
  LAST_READ_ALARM_ID: 'lastReadAlarmId',
  // 방문한 상품을 쭉 기록해 추천상품에 활용
  VISITED_PRODUCT_IDS: 'visitedProductIds',
  // 회원가입 페이지에 접근하기 전 path 기록용
  LAST_PATH: 'lastPath',
  // 신규 홈에 방문해 툴팁을 본 적이 있음 기록용
  VISITED_HOME_TOOL_TIP: 'visitedToolTip',
};

// 변경 대상 스토리지 키값 (구 키값과 현재 키값이 다른 케이스만 지정)
const LOCAL_STORAGE_KEY_OLD = {
  lastClickSection: 'last_click_section',
};

Object.freeze(LOCAL_STORAGE_KEY);
Object.freeze(LOCAL_STORAGE_KEY_OLD);

/**
 * @description 로컬스토리지에 key, value 세팅하는 try catch 함수
 * @param {string} key
 * @param {string | Object} value
 * @returns true | false
 */
const _setLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * @param {string} key
 * @returns data | false
 */
const _getLocalStorage = key => {
  try {
    const data = window.localStorage.getItem(key);

    // 데이터가 있다면 parse해서 내려주고 없다면 false로 실패
    return data ? JSON.parse(data) : false;
  } catch (e) {
    return false;
  }
};

/**
 * @description 로컬스토리지에서 삭제하고싶은 key값 제거 함수
 * @param {string} key
 * @returns true | false
 */
const _removeLocalStorage = key => {
  try {
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * @description 로컬스토리지에 저장되어있는 구 키값이 있다면 해당 키값을 제거하고 신규로 추가
 */
const _checkLocalStorageOldKey = key => {
  if (!key) return;
  const oldKey = LOCAL_STORAGE_KEY_OLD[key];

  if (oldKey) {
    const temp = _getLocalStorage(oldKey);
    _removeLocalStorage(oldKey);
    temp && _setLocalStorage(key, temp);
  }
};

// 앱환경에서 웹뷰로 열렸을 때
export const getIsApp = () =>
  _getLocalStorage(LOCAL_STORAGE_KEY.IS_APP) || false;
export const setIsApp = () => _setLocalStorage(LOCAL_STORAGE_KEY.IS_APP, true);
export const removeIsApp = () => _removeLocalStorage(LOCAL_STORAGE_KEY.IS_APP);

// 마지막 누른 섹션 저장
export const getLastClickSection = () => {
  _checkLocalStorageOldKey(LOCAL_STORAGE_KEY.LAST_CLICK_SECTION);
  return _getLocalStorage(LOCAL_STORAGE_KEY.LAST_CLICK_SECTION) || null;
};
export const setLastClickSection = sectionName => {
  _checkLocalStorageOldKey(LOCAL_STORAGE_KEY.LAST_CLICK_SECTION);
  _setLocalStorage(LOCAL_STORAGE_KEY.LAST_CLICK_SECTION, sectionName);
};

export const removeLastClickSection = () =>
  _removeLocalStorage(LOCAL_STORAGE_KEY.LAST_CLICK_SECTION);

// 마지막 누른 섹션 저장
export const getLastPath = () => {
  _checkLocalStorageOldKey(LOCAL_STORAGE_KEY.LAST_PATH);
  return _getLocalStorage(LOCAL_STORAGE_KEY.LAST_PATH) || null;
};
export const setLastPath = lastPath => {
  _checkLocalStorageOldKey(LOCAL_STORAGE_KEY.LAST_PATH);
  _setLocalStorage(LOCAL_STORAGE_KEY.LAST_PATH, lastPath);
};
export const removeLastPath = () =>
  _removeLocalStorage(LOCAL_STORAGE_KEY.LAST_PATH);

// 오늘 참여 가능한 수업에 뜨는 툴팁 플래그
export const getImmediateToolTip = () =>
  _getLocalStorage(LOCAL_STORAGE_KEY.IMMEDIATE_TOOLTIP) || null;
export const setImmediateToolTip = bool =>
  _setLocalStorage(LOCAL_STORAGE_KEY.IMMEDIATE_TOOLTIP, bool);

// 추천인코드
export const getRecommendCode = () =>
  _getLocalStorage(LOCAL_STORAGE_KEY.RECOMMEND_CODE) || null;
export const setRecommendCode = recommendCode =>
  _setLocalStorage(LOCAL_STORAGE_KEY.RECOMMEND_CODE, recommendCode);
export const removeRecommendCode = () =>
  _removeLocalStorage(LOCAL_STORAGE_KEY.RECOMMEND_CODE);

// 알림센터 마지막으로 읽은 알림 ID
export const getLastReadAlarmId = () =>
  _getLocalStorage(LOCAL_STORAGE_KEY.LAST_READ_ALARM_ID) || -1;
export const setLastReadAlarmId = alarmId =>
  _setLocalStorage(LOCAL_STORAGE_KEY.LAST_READ_ALARM_ID, alarmId);

// 방문한 상품 목록
export const getVisitedProductIds = () =>
  _getLocalStorage(LOCAL_STORAGE_KEY.VISITED_PRODUCT_IDS) || [];

export const updateVisitedProductIds = productId => {
  const productIds = getVisitedProductIds();
  const updated = productIds.filter(id => id !== productId);
  updated.push(productId);

  // 뒤에서 30개씩 잘라서 넣습니다
  _setLocalStorage(LOCAL_STORAGE_KEY.VISITED_PRODUCT_IDS, updated.slice(-30));
};

// 신규 홈 툴팁 노출 관련 스토리지
export const getVisitedHomeToolTip = () =>
  _getLocalStorage(LOCAL_STORAGE_KEY.VISITED_HOME_TOOL_TIP) || null;
export const setVisitedHomeToolTip = () =>
  _setLocalStorage(LOCAL_STORAGE_KEY.VISITED_HOME_TOOL_TIP, true);

export const state = () => ({
  startAt: null,
  endAt: null,
  serverTime: null,
});

export const getters = {
  startAt: state => {
    if (state.startAt !== null) {
      return new Date(state.startAt);
    }
    return null;
  },
  endAt: state => {
    if (state.endAt !== null) {
      return new Date(state.endAt);
    }
    return null;
  },
  paymentEvent: state => state,
};

export const actions = {
  getPaymentEvent({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${window.location.origin}/api/firebase-admin/payment-event`)
        .then(res => {
          const resp = JSON.parse(res?.paymentEvent?.defaultValue?.value);
          const serverTime = res?.nowServerTime;
          commit('SET_PAYMENT_EVENT', { ...resp, serverTime });
          resolve({ ...resp, serverTime });
        })
        .catch(res => {
          console.error(res);
          reject(res);
        });
    });
  },
};

export const mutations = {
  SET_PAYMENT_EVENT: (state, { startAt, endAt, serverTime }) => {
    state.startAt = startAt;
    state.endAt = endAt;
    state.serverTime = serverTime;
  },
};

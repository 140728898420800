
import { mapGetters } from 'vuex';
import { COMPONENT_NAME } from '@/utils/amplitudeNames';
import { sendBridgeDismiss } from '@/utils/webviewBridge.js';

import LoginModal from '@/components/common/modal/LoginModal';
import Divider from '@/components/common/Divider';
import BoxButton from '@/components/common/btn/BoxButton.vue';
import LinkButton from '@/components/common/btn/LinkButton.vue';

export default {
  components: { LoginModal, Divider, BoxButton, LinkButton },
  layout: 'errorLayout', // layouts/errorLayout 사용
  props: {
    error: { type: Object, default: () => ({ statusCode: 0, message: '' }) },
  },
  data() {
    return {
      pageNotFound: this.$route.query.em || '페이지를 찾을 수 없습니다.',
      otherError: this.error.message || '오류가 발생했습니다.',
    };
  },
  async fetch() {
    if (this.error.statusCode === 401) {
      await this.$auth.logout();
      this.$router.replace('/');
    }
  },
  fetchOnServer: false,
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return { title };
  },
  computed: {
    ...mapGetters(['resultBgImg']),
    error401Prop: () => COMPONENT_NAME.error401,
    bgImg() {
      return { backgroundImage: this.resultBgImg };
    },
  },
  methods: {
    onClickEvent() {
      sendBridgeDismiss();
    },
    async kakaoRequestToken() {
      // 웹뷰 닫기
      sendBridgeDismiss();

      await this.$auth.logout();
      this.requestToken({
        section: null,
        componentName: COMPONENT_NAME.loginCta,
      });
    },
  },
};

export const actions = {
  /**
   * @description
   * 홈 화면에 seo에 쓸 검색어, 메타태그 리스틀 받아오는 api
   */
  async getMetaTagList() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/category/tag`,
      );
      return data || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};

import { Node, Renderer } from 'prosemirror-to-html-js';

const renderer = new Renderer();

// 유튜브 영상 임베드용 iframeNode
class IframeNode extends Node {
  matching() {
    return this.node.type === 'iframe';
  }

  tag() {
    return {
      tag: 'iframe',
      attrs: {
        ...this.node.attrs,
        src: `https://www.youtube.com/embed/${this.node.attrs.src}`,
        allowfullscreen: true,
        allow:
          'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
        frameborder: '0',
      },
    };
  }
}

class hrNode extends Node {
  matching() {
    return this.node.type === 'horizontal_rule';
  }

  tag() {
    return {
      tag: 'hr',
    };
  }

  selfClosing() {
    return true;
  }
}

class brNode extends Node {
  matching() {
    return this.node.type === 'hard_break';
  }

  tag() {
    return {
      tag: 'br',
    };
  }

  selfClosing() {
    return true;
  }
}

renderer.addNode(IframeNode);
renderer.addNode(hrNode);
renderer.addNode(brNode);

// TODO: hash 스케줄 상세페이지에서 사용중. 추후 아래 신규 렌더러로 수정할것
function renderContents(product) {
  const renderProduct = { ...product };

  renderProduct.contentsRendered = [
    {
      order: 0,
      title: '수업을 소개해요.',
      key: ['description'],
      html: '',
      sub: [],
    },
  ];

  for (let i = 0; i < renderProduct.contentsRendered.length; i++) {
    const current = renderProduct.contentsRendered[i];
    for (const key of current.key) {
      let [json, html] = '';
      if (renderProduct.contentsNew) {
        json = renderProduct.contentsNew[key];
        html = json && renderer.render(renderProduct.contentsNew[key]);
      } else {
        json = renderProduct.contents[key];
        html = json && renderer.render(renderProduct.contents[key]);
      }
      if (json && typeof json === 'object') {
        if (key === 'description' && html) {
          current.html = html
            .replace(/<iframe/gi, '<div class="video-embed"><iframe')
            .replace(/<\/iframe>/gi, '</iframe></div>')
            .replace(/<a/gi, '<a target="_blank"');
        } else {
          current.html = html;
        }
      }
    }
  }

  return renderProduct;
}

function contentsRenderer(description) {
  const html = description && renderer.render(description);

  return html
    .replace(/<iframe/gi, '<div class="video-embed"><iframe')
    .replace(/<\/iframe>/gi, '</iframe></div>')
    .replace(/<a/gi, '<a target="_blank"');
}

export { contentsRenderer, renderContents };
